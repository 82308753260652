import React, { useState } from "react"
import Layout from "../layout"
import PageTitle from "../components/page-title"
import Panel from "../components/panel"
import PanelContent from "../components/panel/content"
import PanelTitle from "../components/panel/title"
import Seo from "../components/seo"
import meta from "../meta-constants"

function URLDecoder() {
  const { title, description } = meta.urldecoder
  const [formData, setFormData] = useState({
    encoded: "",
    decoded: "",
  })

  const onChange = e => {
    setFormData(formData => ({
      ...formData,
      [e.target.name]: e.target.value,
    }))
  }

  const decode = e => {
    e && e.preventDefault()
    let decodedStr = ""
    try {
      decodedStr = decodeURI(formData.encoded)
    } catch (err) {
      decodedStr = "Failed to encode: " + err.message
    }
    setFormData(formData => ({
      ...formData,
      decoded: decodedStr,
    }))
  }

  return (
    <Layout>
      <Seo title={title} description={description} />
      <PageTitle titleText={title} />
      <Panel>
        <PanelTitle titleText="Convert an Encoded URL and decode to readable format" />
        <PanelContent>
          <form id="form">
            <label htmlFor="encoded">
              Enter text you would like to decode below and click Decode:
            </label>
            <br />
            <input
              type="text"
              id="encoded"
              required="required"
              className="form-control"
              name="encoded"
              onChange={onChange}
              value={formData.encoded}
            />
            <br />
            <span
              tabIndex="0"
              role="button"
              className="btn btn-primary"
              onClick={decode}
              onKeyDown={decode}
            >
              Decode
            </span>
            <br />
            <br />
            <label htmlFor="decoded">
              Decoded text will be shown in below text box :
            </label>
            <input
              type="text"
              id="decoded"
              required="required"
              className="form-control"
              name="decoded"
              onChange={onChange}
              value={formData.decoded}
            />
          </form>
        </PanelContent>
      </Panel>
      <Panel>
        <PanelTitle titleText={"About " + title} />
        <PanelContent>
          <p>{description}</p>
        </PanelContent>
      </Panel>
      <Panel>
        <PanelTitle titleText="What is URL Encoding?" />
        <PanelContent>
          <p>
            Percent-encoding, also known as URL encoding, is a method to encode
            arbitrary data in a Uniform Resource Identifier (URI) using only the
            limited US-ASCII characters legal within a URI. Although it is known
            as URL encoding, it is also used more generally within the main
            Uniform Resource Identifier (URI) set, which includes both Uniform
            Resource Locator (URL) and Uniform Resource Name (URN). As such, it
            is also used in the preparation of data of the
            /x-www-form-urlencoded media type, as is often used in the
            submission of HTML form data in HTTP requests.
          </p>
        </PanelContent>
      </Panel>
      <Panel>
        <PanelTitle titleText={"How to use " + title} />
        <PanelContent>
          <p>Use below steps to encode a URL:</p>
          <ol>
            <li>Open URL Decoder Online Tool</li>
            <li>Enter encoded URL in first text box</li>
            <li>Click on Decode button</li>
            <li>Check the second text box for URL encoded output</li>
          </ol>
        </PanelContent>
      </Panel>
    </Layout>
  )
}

export default URLDecoder
